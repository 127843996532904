$primary: #51b13e;
$primaryDark: darken($primary, 10%);
$primaryLight: lighten($primary, 10%);

// $secondary: ;

$white: #fff;
$black: #000;

$title: #292929;
$text: #8f979e;

$border: #e9ecef;

$bg: #f8f9fa;
$surface: #fff;
$surface100: darken($surface, 10%);
$surface200: darken($surface, 20%);
$surface300: darken($surface, 30%);
$surface400: darken($surface, 40%);
$surface500: darken($surface, 50%);
$surface600: darken($surface, 60%);
$surface700: darken($surface, 70%);
$surface800: darken($surface, 80%);
$surface900: darken($surface, 90%);

$success: #51b13e;
$info: #0f8bff;
$danger: #d13636;
$warning: #ffa700;

$radius: 8px;

@import './reset.scss';
@import './developers.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700|Roboto:300,400,700');

html {
  // background-color: #f8f9fa;
}
footer {
  margin-top: auto;
  padding: 30px 0 0;
  text-align: center;
  background-color: #f8f9fa;
}
footer .logo {
  position: relative;
  margin: 10px auto 0;
  width: 150px;
  height: 50px;
}
footer ul {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
footer ul li {
  padding: 0 15px;
  height: 16px;
  color: #6b6b6b;
  font-size: 18px;
  font-weight: 300;
  border-right: 1px solid #ccc;
  line-height: 16px;
}
footer ul li.emph {
  color: #4e8bff;
}
footer ul li:last-of-type {
  border-right: 0px none;
}
footer p.copy {
  color: #6b6b6b;
  font-size: 14px;
  font-weight: 300;
}
footer .logo img {
  display: block;
  width: 144px;
  height: 50px;
  object-fit: contain;
}
