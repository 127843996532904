.public-page-developers {
  #app {
    height: 100%;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-0 {
    margin-top: 0px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .ml-5 {
    margin-left: 5px;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .width-10 {
    width: 10%;
  }

  .width-15 {
    width: 15%;
  }

  .width-20 {
    width: 20%;
  }
  .width-30 {
    width: 30%;
  }

  .width-40 {
    width: 40%;
  }

  .width-50 {
    width: 50%;
  }

  .width-60 {
    width: 60%;
  }

  .width-65 {
    width: 65%;
  }

  .width-100 {
    width: 100%;
  }

  .width-calc-20 {
    width: calc(20% - 10px);
    margin-right: 10px;
    min-width: 135px;
  }

  .width-calc-25 {
    width: calc(25% - 10px);
    margin-right: 10px;
    min-width: 135px;
  }

  .width-calc-50 {
    width: calc(50% - 10px);
    margin-right: 10px;
    min-width: 135px;
  }

  .width-calc-1-3 {
    width: calc((100% / 3) - 10px);
    margin-right: 10px;
  }
  .width-calc-1-6 {
    width: calc((100% / 6) - 10px);
    margin-right: 10px;
    min-width: 135px;
  }

  .width-calc-75 {
    width: calc(75% - 10px);
    margin-right: 10px;
    min-width: 135px;
  }

  .width-calc-10 {
    width: calc(10% - 10px);
    margin-right: 10px;
    min-width: 135px;
  }

  .is-flex {
    display: flex;
  }

  .is-flex-wrap {
    flex-wrap: wrap;
  }

  .is-justify-content-space-between {
    justify-content: space-between;
  }

  .is-justify-content-start {
    justify-content: start;
  }

  .is-justify-content-center {
    justify-content: center;
  }

  .is-align-items-end {
    align-items: end;
  }

  .is-align-items-center {
    align-items: center;
  }

  .is-align-items-start {
    align-items: start;
  }

  .link {
    color: #0f8bff;
    cursor: pointer;
  }

  th {
    font-weight: 700;
    color: #000;
  }

  .status-table {
    background: unset;
  }

  .text-center {
    text-align: center;
  }

  & {
    font-family: 'Montserrat', sans-serif;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }

  .container-c {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
  }
  .container {
    width: 100%;
    max-width: 95%;
    margin: 0 auto;
  }

  .navigation {
    width: 100%;
    min-height: 76px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }
  .nav-cont {
    width: 100%;
    min-height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #979797;
  }
  .logo-div {
    width: 167px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .logo-div img {
    width: 200px;
  }

  .ul-links {
    display: flex;
  }
  .ul-links li {
    margin: 0 15px;
  }
  .ul-links li a {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }

  /* hamburger btn */

  .burger-div {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
  }

  .burger {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: relative;
    background: none;
    border: none;
    z-index: 10;
    transition: all 0.25s;
  }
  .burger-top,
  .burger-bottom {
    position: absolute;
    top: 7px;
    right: 0;
    height: 2px;
    background: #000;
    transform: rotate(0);
    transition: all 0.5s;
  }
  .burger-top {
    width: 17px;
  }
  .burger-bottom {
    width: 24px;
    transform: translateY(7px);
  }

  .open .burger-top {
    width: 20px;
    transform: rotate(45deg) translateY(3px) translateX(3px);
    background-color: #000;
  }

  .open .burger-bottom {
    width: 20px;
    transform: rotate(-45deg) translateY(3px) translateX(-3px);
    background-color: #000;
  }
  .hidden {
    display: none;
  }

  /* hamburger btn */

  /* mobile nav */

  .mobile-nav {
    width: 100%;
    min-height: 510px;
    background: #fff;
    position: absolute;
    top: 76px;
    padding-top: 10px;
    z-index: 20;
  }
  .mobile-wrap {
    min-height: 510px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 15px;
    border-bottom: 1px solid #979797;
  }

  .nav-links-m {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .nav-links-m li {
    margin-bottom: 10px;
  }
  .nav-links-m li a {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    line-height: 28px;
  }
  .nav-links-m hr {
    background-color: #979797;
    height: 1px;
    width: 50px;
    margin-bottom: 10px;
  }
  .hr2 {
    background-color: #979797;
    height: 1px;
    width: 100%;
    margin-bottom: 10px;
  }
  .mobile-wrap h3 {
    font-size: 13px;
    font-weight: 600;
    color: #495057;
    margin-bottom: 5px;
  }
  .mobile-wrap a {
    font-size: 13px;
    font-weight: 500;
    color: #495057;
    margin-bottom: 10px;
  }
  .mobile-wrap h5 {
    font-size: 11px;
    font-weight: 400;
    color: #000;
    margin: 0 auto;
  }
  .small-nav-links {
    margin-bottom: 10px;
  }
  .small-nav-links a {
    font-size: 13px;
    font-weight: 500;
    color: #495057;
  }

  .nav-info-m {
    display: flex;
    height: 25px;
    margin-bottom: 132px;
  }
  .nav-info-m .select {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    border: none;
    user-select: none;
    margin-right: 10px;
    background: transparent;
  }
  .num-m {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    line-height: 27px;
  }
  /* mobile nav */

  .header {
    background-color: #fff;
    position: relative;
  }
  .header-cont {
    /*  padding:77px 10px 120px 10px; */
  }
  .header-cont-top {
    width: 100%;
    min-height: 620px;
    display: flex;
  }
  .top-left {
    width: 100%;
    max-width: 50%;
    min-height: 620px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .top-left h4 {
    font-size: 28px;
    font-weight: 500;
    line-height: 67px;
    color: #4a4a4a;
  }
  .top-left .header-big-logo {
    margin-bottom: 24px;
  }
  .top-left h3 {
    font-size: 36px;
    font-weight: 600;
    line-height: 26px;
    color: #000;
    margin-bottom: 20px;
  }
  .top-left p {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #000;
    margin-bottom: 20px;
  }
  .top-left a {
    font-size: 18px;
    font-weight: 600;
    color: #3e80ff;
    text-decoration: underline;
  }

  .top-right {
    width: 100%;
    max-width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .right-img {
    width: 100%;
    max-width: 320px;
  }

  .right-img img {
    width: 100%;
  }
  .right-img-mob {
    width: 100%;
    max-width: 320px;
    display: none;
  }
  .right-img-mob img {
    width: 100%;
  }

  .header-cont-btm {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btm-div {
    width: 100%;
    max-width: 300px;
    min-height: 183px;
    padding: 40px 20px;
    border-radius: 23px;
    border: 3px solid transparent;
    transition: all ease-in-out 0.2s;
  }
  .btm-div:hover {
    border: 3px solid #3e80ff;
  }

  .btm-f {
    display: flex;
  }
  .btm-img {
    margin-right: 15px;
  }
  .btm-div p {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #000;
  }
  .btm-f h3 {
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    color: #000;
    margin-bottom: 10px;
  }

  .footer {
    width: 100%;
    min-height: 155px;
  }
  .footer-cont {
    width: 100%;
    min-height: 155px;
    border-top: 1px solid #979797;
  }
  .footer-cont-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #979797;
  }
  .ul-div {
    width: 100%;
    max-width: 450px;
    padding: 11px 0;
    display: flex;
    justify-content: space-between;
  }
  .foot-nav a {
    font-size: 13px;
    font-weight: 500;
    color: #495057;
  }
  .foot-nav .bold {
    font-size: 13px;
    font-weight: 600;
    color: #495057;
  }
  .ma {
    margin-bottom: 10px;
  }

  .footer-cont-btm {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  .footer-cont-btm h2 {
    font-size: 11px;
    font-weight: 400;
    color: #000;
  }
  .foot-logo {
    width: 148px;
    height: 42px;
  }
  .foot-logo img {
    width: 100%;
  }

  /* privacy page start */

  .privacy-cont {
    min-height: 80vh;
    padding: 100px 20px 10px 20px;
  }
  .privacy-cont h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
    color: #000;
    height: 66px;
  }
  .privacy-cont h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #000;
    height: 66px;
  }
  .privacy-cont p {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #000;
    margin-bottom: 30px;
  }

  /* REGISTER PAGE START  */

  .header-cont-reg {
    min-height: 688px;
    width: 100%;
    display: flex;
  }
  .hc-left-part {
    width: 100%;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .hc-left-part h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 67px;
    color: #4a4a4a;
    margin-bottom: 10px;
  }
  .reg-form {
    width: 100%;
    max-width: 680px;
    display: flex;
    flex-direction: column;
  }
  .form-group-inp {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .last {
    padding-bottom: 0;
    margin-bottom: 30px;
  }
  .form-group-inp input,
  select {
    width: 330px;
    min-height: 46px;
    border: 0.6px solid #495057;
    border-radius: 23px;
    padding: 0 15px;
  }
  .form-group-inp input::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: #616f7b;
  }
  .form-group-inp select {
    font-size: 14px;
    font-weight: 600;
    color: #616f7b;
  }

  .check-row {
    display: flex;
    align-items: center;
    margin-bottom: 47px;
  }
  .check-row p {
    padding-left: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #495057;
  }
  .check-row a {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #139ada;
    text-decoration: underline;
  }
  .reg-btn {
    width: 100%;
    max-width: 320px;
    min-height: 46px;
    align-self: flex-end;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 28px;
    background: rgb(62, 128, 255);
    background: linear-gradient(
      270deg,
      rgba(62, 128, 255, 1) 12%,
      rgba(124, 42, 248, 1) 100%,
      rgba(124, 42, 248, 1) 100%
    );
  }
  .reg-btn::after {
    content: '>';
    width: 10px;
    height: 10px;
    color: #fff;
    position: absolute;
    right: 20px;
    top: 35%;
    font-size: 18px;
  }

  .hc-right-part {
    width: 100%;
    max-width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .r-part-img {
    width: 100%;
    max-width: 341px;
    min-height: 358px;
  }
  .r-part-img img {
    width: 100%;
  }
  .text-center {
    text-align: center;
  }

  .error-box > div:first-child {
    border: 1px solid red;
  }
  /* REGISTER PAGE END */

  @media (max-width: 1024px) {
    .nav-cont {
      padding: 0 10px;
    }
    .header-cont {
      padding: 30px 10px;
    }

    .top-left h4 {
      font-size: 24px;
      font-weight: 500;
      line-height: 47px;
    }
    .top-left .header-big-logo {
      max-width: 100%;
      width: 394px;
      min-height: 103px;
      margin-bottom: 15px;
    }
    .top-left .header-big-logo img {
      max-width: 100%;
      width: 394px;
      min-height: 103px;
    }
    .top-left h3 {
      font-size: 18px;
    }
    .right-img {
      width: 100%;
      max-width: 311px;
      min-height: 333px;
    }

    .btm-f h3 {
      font-size: 22px;
    }
    .btm-div {
      max-width: 260px;
    }
    .footer {
      padding: 0 10px;
    }

    .top-right {
      background-color: #fff;
      max-width: 100%;
      padding: 20px 15px;
      justify-content: center;
    }
    .top-left {
      max-width: 100%;
    }

    /* REGISTER PAGE START */
    .header-cont-reg {
      flex-direction: column;
    }
    .hc-left-part {
      max-width: 100%;
      padding: 30px 10px 30px 100px;
    }
    .hc-right-part {
      max-width: 100%;
      padding: 30px 10px;
      justify-content: center;
    }

    /* REGISTER PAGE END */
  }
  /* 1024 WIDTH END    */

  @media (max-width: 768px) {
    .logo-div {
      width: 147px;
      height: 35px;
    }
    .burger-div {
      display: block;
    }
    .ul-links {
      display: none;
    }

    .header-cont {
      padding: 20px 10px;
    }
    .header-cont-top {
      width: 100%;
      min-height: 436px;
      flex-direction: column;
      margin-bottom: 47px;
    }
    .top-left {
      min-height: 436px;
      padding: 10px 40px;
    }
    .top-left h4 {
      font-size: 24px;
    }
    .top-left .header-big-logo {
      width: 100%;
      max-width: 568px;
      min-height: 93px;
    }
    .top-left h3 {
      font-size: 18px;
    }

    .header-cont-btm {
      flex-direction: column;
    }
    .btm-div {
      max-width: 300px;
      margin-bottom: 23px;
    }
    .ul-div {
      max-width: 300px;
    }

    /* REGISTER PAGE START */

    .hc-left-part {
      max-width: 100%;
      padding: 30px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .reg-form {
      align-items: center;
    }

    .form-group-inp {
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;
    }
    .form-group-inp input {
      margin-bottom: 20px;
    }
    .form-group-inp select {
      margin-bottom: 20px;
    }
    .reg-btn {
      align-self: center;
    }

    /* REGISTER PAGE END */
  }
  /* 768 WIDTH END    */

  @media (max-width: 425px) {
    .container-c {
      max-width: 95%;
    }
    .logo-div {
      width: 111px;
      height: 23px;
    }
    .header-cont {
      padding: 40px 10px 20px 10px;
    }
    .top-left {
      min-height: 436px;
      padding: 0;
    }
    .top-left h4 {
      font-size: 18px;
      line-height: 27px;
    }
    .top-left .header-big-logo {
      max-width: 240px;
      min-height: 49px;
      margin-bottom: 40px;
    }
    .top-left .header-big-logo img {
      width: 100%;
      min-height: 100%;
    }
    .top-left h4 {
      font-size: 18px;
      line-height: 47px;
    }
    .top-left h3 {
      font-size: 16px;
      line-height: 18px;
    }
    .top-left p {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 15px;
      max-width: 291px;
    }
    .top-left a {
      font-size: 16px;
    }

    .right-img {
      display: none;
    }
    .right-img-mob {
      display: block;
      max-width: 172px;
      min-height: 181px;
      margin: 0 auto;
      margin-bottom: 47px;
    }
    .right-img-mob img {
      max-width: 100%;
      min-height: 100%;
    }

    .btm-div p {
      max-width: 259px;
    }
    .footer-cont-top {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid #979797;
    }
    .ul-div {
      max-width: 300px;
      flex-direction: column;
      margin-bottom: 23px;
    }
    .foot-logo {
      margin-bottom: 30px;
    }

    /* REGISTER PAGE START */

    .hc-left-part h1 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 34px;
    }
    .form-group-inp input {
      margin-bottom: 20px;
      width: 300px;
    }
    .form-group-inp select {
      margin-bottom: 20px;
      width: 300px;
    }
    .check-row {
      align-items: flex-start;
    }
    .check-row input {
      margin-top: 5px;
    }
    .hc-right-part {
      display: none;
    }

    /* REGISTER PAGE END */
  }
  /* 425 WIDTH END    */

  .navigation {
    position: relative;
    z-index: 10;
  }
  .ul-links li.submenu {
    position: relative;
    ul {
      visibility: hidden;
      position: absolute;
      top: 100%;
      right: 0;
      white-space: nowrap;
      background: white;
      padding: 20px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      li {
        display: block;
        a {
          display: block;
          padding: 1em 8px 1em 16px;
          margin: 0;
        }
        position: relative;
      }
    }
    &:hover ul {
      visibility: visible;
    }
  }

  section.main-content {
    display: flex;
  }
  aside.side-nav {
    background: rgb(250, 250, 250);
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow: auto;
    width: 260px;
    flex-shrink: 0;

    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
    color: rgb(51, 51, 51);
    text-align: left;
    text-rendering: optimizespeed !important;

    * {
      box-sizing: border-box;
    }
    > ul {
      list-style: none;
      > li {
        list-style: inside none;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px;
        &.collapsed {
          > label svg {
            transform: rotateZ(-90deg);
          }
          > ul {
            // display: none;
          }
        }
        > label {
          cursor: pointer;
          color: rgb(51, 51, 51);
          margin: 0px;
          > a,
          > span {
            display: block;
            padding: 12.5px 20px;
            flex-grow: 1;
          }
          display: flex;
          -moz-box-pack: justify;
          justify-content: space-between;
          font-family: Montserrat, sans-serif;
          // font-size: 0.929em;
          text-transform: none;
          background-color: rgb(250, 250, 250);
          &:hover {
            color: rgb(50, 50, 159);
            background-color: rgb(225, 225, 225);
          }
          svg {
            height: 18px;
            width: 18px;
            min-width: 18px;
            vertical-align: middle;
            float: right;
            transition: transform 0.2s ease-out;
            transform: rotateZ(0deg);
          }
          &[type='group'] {
            background: none;
            pointer-events: none;
            color: rgb(51, 51, 51);
            margin: 0px;
            padding: 12.5px 20px 0px;
            display: flex;
            -moz-box-pack: justify;
            justify-content: space-between;
            font-family: Montserrat, sans-serif;
            opacity: 0.7;
            text-transform: uppercase;
            font-size: 0.8em;
            cursor: default;
            background-color: rgb(250, 250, 250);
          }
        }
        > ul > li > label {
          cursor: pointer;
          color: rgb(51, 51, 51);
          margin: 0px;
          > a,
          > span {
            display: block;
            padding: 12.5px 20px 12.5px 40px;
            flex-grow: 1;
          }
          display: flex;
          -moz-box-pack: justify;
          justify-content: space-between;
          font-family: Montserrat, sans-serif;
          background-color: rgb(250, 250, 250);
          &:hover {
            color: rgb(50, 50, 159);
            background-color: rgb(225, 225, 225);
          }
        }
      }
    }
  }
  div.dev-content {
    flex-grow: 1;
  }

  .dev-text-content {
    max-width: 1200px;
    padding: 30px 20px;
    margin: 0 auto;
    text-align: justify;
    line-height: 1.5em;
    @media screen and (max-width: 760px) {
      padding: 20px 20px 24px;
    }
    h1 {
      font-size: 36px;
      font-weight: bold;
      color: #495057;
      margin: 0 0 1em 0;
      padding-top: 1em;
    }
    h2 {
      font-weight: bold;
      margin: 1em 0;
    }
    p {
      margin: 0.5em 0;
    }
  }
}
